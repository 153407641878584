import { default as abuseReportsgmyAFpOyBgMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93asg2KmG0xXMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexRzVL3WyOw3Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/index.vue?macro=true";
import { default as moderationKM4j9P68OJMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/moderation.vue?macro=true";
import { default as pendingBans3L0lpR0CahMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/pendingBans.vue?macro=true";
import { default as profilescqc5KuGaWiMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/profiles.vue?macro=true";
import { default as expenseskMnLyY6i9pMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexUALU7VSvN5Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewpSImtLa1OTMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingXUrkNkNFi9Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingrObnhAiMZ2Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/translations/missing.vue?macro=true";
import { default as users4ixXGr6vm5Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/users.vue?macro=true";
import { default as apiMs9bPXC2CBMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/api.vue?macro=true";
import { default as _91slug_93rALgX9QTOmMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/blog/[slug].vue?macro=true";
import { default as indexEjC33Gzy7UMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93IAjAyPa2TIMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93vGTuwSpO04Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminPOeBjlyR8GMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/census/admin.vue?macro=true";
import { default as indexxdPWmNF2flMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/census/index.vue?macro=true";
import { default as contact9IH3reRsLtMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/contact.vue?macro=true";
import { default as designuNkC4hWXjmMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/design.vue?macro=true";
import { default as englishNTgD9yor7vMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/english.vue?macro=true";
import { default as faq296RrzV9V6Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/faq.vue?macro=true";
import { default as inclusivex6GXWyMk9xMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/inclusive.vue?macro=true";
import { default as indexjBUSm3wtpMMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/index.vue?macro=true";
import { default as licenset9GPSaETl7Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/license.vue?macro=true";
import { default as academiczZx8bsRi8MMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/academic.vue?macro=true";
import { default as indexk3R0BUANMwMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/index.vue?macro=true";
import { default as mediafyfohlPqyGMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/media.vue?macro=true";
import { default as translinguisticshfF06JYuHeMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/translinguistics.vue?macro=true";
import { default as zineVnhL3gGKgMMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/zine.vue?macro=true";
import { default as names7C5soHeEpHMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/names.vue?macro=true";
import { default as indexyO5ByR68VuMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/nouns/index.vue?macro=true";
import { default as templateslucBQhktrzMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/nouns/templates.vue?macro=true";
import { default as peopleCUoWl1GMthMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/people.vue?macro=true";
import { default as privacyFbzMbhs858Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/privacy.vue?macro=true";
import { default as _91username_93HUlslsVKAxMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93mDfAZ7BplfMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/card-[username].vue?macro=true";
import { default as editorMHqjBI7IXhMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/editor.vue?macro=true";
import { default as anyBnTB4b4YcVMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/any.vue?macro=true";
import { default as askP72ejgvcYjMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/ask.vue?macro=true";
import { default as avoiding7jgLYi9xr6Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/avoiding.vue?macro=true";
import { default as indexs8lKgBgkoVMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/index.vue?macro=true";
import { default as mirrorI0ELYVOpmPMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun3Hk0ZMkzAoMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesG38IA6wJV1Meta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/sources.vue?macro=true";
import { default as teamYfugddf7IRMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/team.vue?macro=true";
import { default as terminologyQ8h8BErEqnMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/terminology.vue?macro=true";
import { default as termspBmQJAPv6yMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/terms.vue?macro=true";
import { default as user0ecrsfpqthMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/user.vue?macro=true";
import { default as workshopsQVpjYlJHPHMeta } from "/home/admin/www/en.pronouns.page/release/20241214155632/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsgmyAFpOyBgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans3L0lpR0CahMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiMs9bPXC2CBMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93rALgX9QTOmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexEjC33Gzy7UMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93IAjAyPa2TIMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93vGTuwSpO04Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminPOeBjlyR8GMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexxdPWmNF2flMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact9IH3reRsLtMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishNTgD9yor7vMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq296RrzV9V6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivex6GXWyMk9xMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academiczZx8bsRi8MMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexk3R0BUANMwMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediafyfohlPqyGMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticshfF06JYuHeMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineVnhL3gGKgMMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names7C5soHeEpHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexyO5ByR68VuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templateslucBQhktrzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleCUoWl1GMthMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyFbzMbhs858Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93HUlslsVKAxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93mDfAZ7BplfMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorMHqjBI7IXhMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyBnTB4b4YcVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askP72ejgvcYjMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding7jgLYi9xr6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexs8lKgBgkoVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorI0ELYVOpmPMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun3Hk0ZMkzAoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesG38IA6wJV1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamYfugddf7IRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyQ8h8BErEqnMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termspBmQJAPv6yMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user0ecrsfpqthMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsQVpjYlJHPHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214155632/pages/workshops.vue")
  }
]